var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Desktop as ResponsiveDesktop, Mobile as ResponsiveMobile } from '@/responsive';
import DesktopComponent from './desktop';
import MobileComponent from './mobile';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Component.prototype.render = function () {
        var containerStyle = {
            position: 'relative',
            zIndex: 2000,
        };
        if (!this.props.touchable) {
            containerStyle.pointerEvents = 'none';
            containerStyle.display = 'none'; // IE 10을 위해 이렇게 해놨는데 나중에는 conditional 하게 바꿔야함
        }
        return (React.createElement("div", { style: containerStyle },
            React.createElement(ResponsiveDesktop, null,
                React.createElement(DesktopComponent, null)),
            React.createElement(ResponsiveMobile, null,
                React.createElement(MobileComponent, null))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            touchable: stores.ui.popup.opened,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
