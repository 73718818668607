var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import { Type as PopupType } from '@/stores/ui/popup';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var openPopup = _this.props.openPopup;
            openPopup(_this.props.entity);
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "entity", {
        get: function () {
            return this.props.entity || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "dateString", {
        get: function () {
            var time = this.entity.time || 0;
            var date = new Date(time * 1000);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return year + '년 ' + month + '월 ' + day + '일';
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        var _this = this;
        var _a = this.props.entity, /*id,*/ name = _a.name, title = _a.title, /*gallery,*/ contents = _a.contents;
        var dateString = this.dateString;
        var encryptedName = name.charAt(0) + name.substr(1).replace(/./g, '○');
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", null,
                React.createElement("div", { className: styles.title }, title),
                React.createElement("div", { className: styles.subtitle },
                    encryptedName + ',',
                    "\u00A0\u00A0",
                    dateString),
                React.createElement("div", { className: styles.contents }, contents),
                React.createElement("div", { className: styles.moreButton, onClick: function () { return _this.handleClick(); } }, "\uC790\uC138\uD788 \uBCF4\uAE30"))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            openPopup: function (entity) {
                var store = stores.ui.popup;
                store.open(PopupType.Article, entity);
            }
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
