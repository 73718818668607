var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.render = function () {
        var inlineStyle = this.props.style || {};
        var name = (this.props.name || '').toLowerCase();
        var size = this.props.size || 'normal';
        if (typeof size === 'number') {
            inlineStyle.width = size + 'px';
            inlineStyle.height = size + 'px';
            size = '';
        }
        if (this.props.flipX || this.props.flipY)
            inlineStyle.transform = 'scale( ' + (this.props.flipX ? -1 : 1) + ', ' + (this.props.flipY ? -1 : 1) + ')';
        var color = this.props.color;
        if (color)
            inlineStyle.fill = color;
        if (this.props.opacity) {
            if (this.props.opacity === 'disabled')
                inlineStyle.opacity = 0.25;
            else if (this.props.opacity === 'unfocused')
                inlineStyle.opacity = 0.5;
            else
                inlineStyle.opacity = this.props.opacity;
        }
        var $icon = null;
        switch (name) {
            case 'youtube':
                $icon = (React.createElement("img", { src: "/static/icons/youtube.svg" }));
                break;
            case 'facebook':
                $icon = (React.createElement("img", { src: "/static/icons/facebook.svg" }));
                break;
            case 'instagram':
                $icon = (React.createElement("img", { src: "/static/icons/instagram.svg" }));
                break;
            case 'like':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M5,25h4V13H5V25z M27,14c0-1.1-0.9-2-2-2h-6.3l0.9-4.6l0-0.3c0-0.4-0.2-0.8-0.4-1.1L18.2,5l-6.6,6.6C11.2,11.9,11,12.4,11,13v10c0,1.1,0.9,2,2,2h9c0.8,0,1.5-0.5,1.8-1.2l3-7.1C27,16.5,27,16.3,27,16V14L27,14L27,14z" })));
                break;
            case 'dislike':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M27,7h-4v12h4V7z M5,18c0,1.1,0.9,2,2,2h6.3l-0.9,4.6l0,0.3c0,0.4,0.2,0.8,0.4,1.1l1.1,1l6.6-6.6C20.8,20,21,19.5,21,19V9c0-1.1-0.9-2-2-2h-9C9.2,7,8.5,7.5,8.2,8.2l-3,7.1C5,15.5,5,15.7,5,16V18L5,18L5,18z" })));
                break;
            case 'person':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M16,16c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S12.7,16,16,16z M16,19c-4,0-12,2-12,6v3h24v-3C28,21,20,19,16,19z" })));
                break;
            case 'star':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M16,22.9l7.4,4.5l-2-8.4l6.6-5.7l-8.6-0.7l-3.4-8l-3.4,8L4,13.3l6.6,5.7l-2,8.4L16,22.9z" })));
                break;
            case 'view':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M16,7.1c-5.9,0-11,3.7-13,8.9c2,5.2,7.1,8.9,13,8.9c5.9,0,11-3.7,13-8.9C27,10.8,21.9,7.1,16,7.1z M16,22c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S19.3,22,16,22z M16,13c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S17.7,13,16,13z" })));
                break;
            case 'comment':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M28,25.5c-2-1.7-3-4.2-3-7c0,0,0-1.3,0-2c0,0,0,0,0,0C25,10.7,20.3,6,14.5,6S4,10.7,4,16.5C4,22.3,8.7,27,14.5,27c2.8,0,5.4-1.1,7.3-3C23.7,24.9,25.7,25.5,28,25.5z" })));
                break;
            case 'search':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M26.7,28.1l-8-8c-1.6,1.3-3.6,2-5.6,2c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9c0,2.1-0.7,4-2,5.6l8,8L26.7,28.1z M13,6c-3.9,0-7,3.1-7,7s3.1,7,7,7c1.9,0,3.6-0.7,4.9-2l0,0c1.3-1.3,2-3.1,2-4.9C20,9.1,16.9,6,13,6z" })));
                break;
            case 'expand':
                $icon = (React.createElement("svg", { viewBox: "0 0 24 24" },
                    React.createElement("polygon", { points: "12,17.9 2.3,8.2 3.7,6.8 12,15.1 20.3,6.8 21.7,8.2 " })));
                break;
            case 'expand-short':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("polygon", { points: "16,19.1 4.7,14.9 5.3,13.1 16,16.9 26.7,13.1 27.3,14.9 " })));
                break;
            case 'phone':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M22.5,26.9l-1.2,0.7C14.3,31.2,2.2,10.1,9,6.1l1.1-0.6l3.7,6.5l-1.1,0.6c-2.1,1.2,2.8,9.7,4.9,8.5l1.2-0.6\n\t\t\t\t\t\t\tC18.8,20.5,22.5,26.9,22.5,26.9z M12.7,4L11,5l3.7,6.5l1.7-1L12.7,4z M21.3,19l-1.7,1l3.7,6.5l1.7-1C25,25.5,21.3,19,21.3,19z" })));
                break;
            case 'arrow':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 29.4" },
                    React.createElement("polygon", { points: "17.2,29.4 15.5,27.6 27.1,16 0,16 0,13.4 27.1,13.4 15.5,1.8 17.2,0 31.9,14.7 " })));
                break;
            case 'check':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("polygon", { points: "12.5,23.4 4.1,15 5.5,13.6 12.5,20.6 26.5,6.6 27.9,8 " })));
                break;
            case 'map':
                $icon = (React.createElement("svg", { viewBox: "0 0 32 32" },
                    React.createElement("path", { d: "M10.9,4c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v19.7c0,0.2-0.1,0.3-0.2,0.4l-6.4,3.4C4.6,28,4.5,28,4.4,28\n\tc-0.1,0-0.2,0-0.3-0.1C4,27.8,4,27.7,4,27.6V7.9c0-0.2,0.1-0.3,0.2-0.4l6.4-3.4C10.7,4,10.8,4,10.9,4z M27.6,4c0.1,0,0.2,0,0.3,0.1\n\tC28,4.2,28,4.3,28,4.4v19.7c0,0.2-0.1,0.3-0.2,0.4l-6.4,3.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3V7.9\n\tc0-0.2,0.1-0.3,0.2-0.4l6.4-3.4C27.4,4,27.5,4,27.6,4z M12.6,4c0.1,0,0.1,0,0.2,0l6.9,3.4c0.2,0.1,0.2,0.2,0.2,0.4v19.7\n\tc0,0.1,0,0.2-0.1,0.3C19.6,28,19.5,28,19.4,28c-0.1,0-0.1,0-0.2,0l-6.9-3.4c-0.2-0.1-0.2-0.2-0.2-0.4V4.4c0-0.1,0-0.2,0.1-0.3\n\tC12.4,4,12.5,4,12.6,4z" })));
                break;
            case 'close':
                $icon = (React.createElement("svg", { viewBox: "0 0 357 357" },
                    React.createElement("g", null,
                        React.createElement("polygon", { points: "357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 \n\t\t\t\t\t\t\t\t214.2,178.5" }))));
                break;
            case 'eye':
                $icon = (React.createElement("svg", { viewBox: "0 0 488.85 488.85" },
                    React.createElement("g", null,
                        React.createElement("path", { d: "M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2\n\t\t\t\t\t\ts178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025\n\t\t\t\t\t\tc-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3\n\t\t\t\t\t\tC343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7\n\t\t\t\t\t\tc33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z" }))));
                break;
        }
        return (React.createElement("div", { style: inlineStyle }, $icon));
    };
    return default_1;
}(React.Component));
export default default_1;
