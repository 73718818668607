var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import Carousel from 'nuka-carousel';
import styles from './style.scss';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setName = function (name) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.name = name;
        };
        _this.setPhone1 = function (phone1) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.phone1 = phone1;
        };
        _this.setPhone2 = function (phone2) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.phone2 = phone2;
        };
        _this.setPhone3 = function (phone3) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.phone3 = phone3;
        };
        _this.setContents = function (contents) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.contents = contents;
        };
        _this.setAgree = function (agree) {
            var requestSectionStore = _this.requestSectionStore;
            requestSectionStore.agree = agree;
        };
        _this.submit = function () { return __awaiter(_this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.name || !this.name.trim().length ||
                            !this.phone1 || !this.phone1.trim().length ||
                            !this.phone2 || !this.phone2.trim().length ||
                            !this.phone3 || !this.phone3.trim().length ||
                            !this.contents || !this.contents.trim().length) {
                            alert('내용을 모두 입력해주세요.');
                            return [2 /*return*/];
                        }
                        if (!this.agree) {
                            alert('약관에 동의해주세요.');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.requestStore.submit()];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            this.setName('');
                            this.setPhone1('');
                            this.setPhone2('');
                            this.setPhone3('');
                            this.setContents('');
                            this.setAgree(false);
                            alert('성공적으로 보냈습니다. 빠른 시일내로 연락을 드리겠습니다.');
                        }
                        else {
                            alert('[오류] 알 수 없는 이유로 보내지 못했습니다.');
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    Component.prototype.componentDidMount = function () {
        var _this = this;
        setInterval(function () {
            _this.refs.carousel.setDimensions();
            // fir for IE 10 below : height 0
        }, 500);
    };
    Object.defineProperty(Component.prototype, "requestStore", {
        get: function () {
            return this.props.requestStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "requestSectionStore", {
        get: function () {
            return this.props.requestSectionStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "name", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.name || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "phone1", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.phone1 || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "phone2", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.phone2 || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "phone3", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.phone3 || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "contents", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.contents || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "agree", {
        get: function () {
            var requestSectionStore = this.requestSectionStore;
            return requestSectionStore.agree || false;
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        var _this = this;
        var images = this.props.images;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.title }, "\uC0C1\uB2F4"),
                React.createElement("div", null,
                    React.createElement("table", { style: { tableLayout: 'fixed', width: '100%' } },
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: '45%', position: 'relative' } },
                                    React.createElement("div", { className: styles.inputSection },
                                        React.createElement("table", null,
                                            React.createElement("tbody", null,
                                                React.createElement("tr", null,
                                                    React.createElement("td", null,
                                                        React.createElement("span", { className: styles.label }, "\uACE0\uAC1D\uBA85")),
                                                    React.createElement("td", null,
                                                        React.createElement("input", { className: styles.textField, type: "text", placeholder: "\uC131\uD568\uC744 \uC801\uC5B4\uC8FC\uC138\uC694", value: this.name, onChange: function (e) { return _this.setName(e.target.value); } }))),
                                                React.createElement("tr", null,
                                                    React.createElement("td", null,
                                                        React.createElement("span", { className: styles.label }, "\uC5F0\uB77D\uCC98")),
                                                    React.createElement("td", null,
                                                        React.createElement("input", { className: styles.textField, "data-field-type": "phone", type: "text", placeholder: "000", value: this.phone1, onChange: function (e) { return _this.setPhone1(e.target.value); } }),
                                                        React.createElement("input", { className: styles.textField, "data-field-type": "phone", type: "text", placeholder: "0000", value: this.phone2, onChange: function (e) { return _this.setPhone2(e.target.value); } }),
                                                        React.createElement("input", { className: styles.textField, "data-field-type": "phone", type: "text", placeholder: "0000", value: this.phone3, onChange: function (e) { return _this.setPhone3(e.target.value); } }))))),
                                        React.createElement("div", null,
                                            React.createElement("div", { className: styles.label }, "\uB0B4\uC6A9"),
                                            React.createElement("textarea", { className: styles.textArea, placeholder: "\uBB34\uC5C7\uC774\uB4E0 \uBB3C\uC5B4\uBCF4\uC138\uC694", value: this.contents, onChange: function (e) { return _this.setContents(e.target.value); } })),
                                        React.createElement("div", null,
                                            React.createElement("div", null,
                                                React.createElement("input", { type: "checkbox", id: "checkbox2", className: styles.checkBox, checked: this.agree, onChange: function (e) { return _this.setAgree(!!e.target.value); } }),
                                                React.createElement("label", { htmlFor: "checkbox2" }, "(\uD544\uC218) \uAC1C\uC778\uC815\uBCF4 \uCC98\uB9AC\uBC29\uCE68 \uB3D9\uC758"))),
                                        React.createElement("div", null,
                                            React.createElement("div", { className: styles.submitButton, onClick: this.submit }, "\uBCF4\uB0B4\uAE30")))),
                                React.createElement("td", { style: { width: '55%', position: 'relative' } },
                                    React.createElement("div", { className: styles.imageSection },
                                        React.createElement(Carousel, { ref: "carousel", renderCenterRightControls: function (_) { return (null); }, renderCenterLeftControls: function (_) { return (null); }, renderBottomCenterControls: function (_) { return (null); }, easing: 'easeQuadInOut', edgeEasing: 'easeQuadInOut', swiping: false, dragging: false, autoplay: true, autoplayInterval: 10000, speed: 1000, transitionMode: 'fade', afterSlide: function (currentSlideIndex) {
                                                if (currentSlideIndex === images.length - 1) {
                                                    setTimeout(function () {
                                                        _this.refs.carousel.goToSlide(0);
                                                    }, 5000);
                                                }
                                            } }, images.map(function (section, index) { return (React.createElement("div", { key: index, className: styles.image, style: { backgroundImage: "url(" + section.image.sizes.large + ")" } })); })))))))))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            requestStore: stores.domain.request,
            requestSectionStore: stores.ui.requestSection,
            images: (stores.domain.config.requestSection || {}).sections || [],
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
// todo : 여기에 있는것들 모두 상위 공통 기능으로 옮겨야함 너무 비효율
