var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Component.prototype, "officeStore", {
        get: function () {
            return this.props.officeStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "offices", {
        get: function () {
            return this.officeStore.entities || [];
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.title }, "\uC0AC\uBB34\uC2E4 \uC815\uBCF4"),
                React.createElement("div", null,
                    this.offices.map(function (office, index) { return (React.createElement(React.Fragment, { key: index },
                        React.createElement("div", { className: styles.card },
                            React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + office.image + ")" } }),
                            React.createElement("div", { className: styles.name }, office.name),
                            React.createElement("table", null,
                                React.createElement("tbody", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", null, "\uC5F0\uB77D\uCC98"),
                                        React.createElement("td", null, office.phone)),
                                    React.createElement("tr", null,
                                        React.createElement("td", null, "\uC8FC\uC18C"),
                                        React.createElement("td", null, office.address)),
                                    React.createElement("tr", null,
                                        React.createElement("td", null, "\uB2F4\uB2F9\uC790"),
                                        React.createElement("td", null, "\uAE40\uC21C\uC7AC")))),
                            React.createElement("div", { className: styles.callButton, onClick: function () { return window.open("https://map.kakao.com/?q=" + encodeURIComponent(office.address.split(',')[0]), '_blank'); } }, "\uC9C0\uB3C4 \uBCF4\uAE30")))); }),
                    React.createElement("div", { style: { clear: 'both' } })))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            officeStore: stores.domain.office,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
