var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { NavigationMenu } from '@/enums';
import { observable, action } from 'mobx';
import scrollTransitionStore from '@/stores/ui/scroll/transition';
import homeSectionStore from '@/stores/ui/homeSection';
import workSectionStore from '@/stores/ui/workSection';
import reviewSectionStore from '@/stores/ui/reviewSection';
import officeSectionStore from '@/stores/ui/officeSection';
import requestSectionStore from '@/stores/ui/requestSection';
var Store = /** @class */ (function () {
    function Store() {
        var _this = this;
        // 메뉴
        this.menu = NavigationMenu.Home;
        // 화면 정중앙에 가로로 그은 선. 현재 어떤 메뉴에 있는지 알기 위해 존재. (react-visibility-sensor 모듈에서 사용)
        this.indicator = React.createRef(); // todo : 이름을 indicatorRef 로 고쳐야하지 않을까? 아니 그냥 이름만 visibilitySensor로 바꾸면 되잖아
        // 메뉴 변경
        this.change = function (menu, moveScroll) {
            if (moveScroll === void 0) { moveScroll = false; }
            _this.menu = menu;
            if (moveScroll) {
                switch (menu) {
                    case NavigationMenu.Home:
                        scrollTransitionStore.animateTo(homeSectionStore.ref.current);
                        break;
                    case NavigationMenu.Work:
                        scrollTransitionStore.animateTo(workSectionStore.ref.current);
                        break;
                    case NavigationMenu.Review:
                        scrollTransitionStore.animateTo(reviewSectionStore.ref.current);
                        break;
                    case NavigationMenu.Office:
                        scrollTransitionStore.animateTo(officeSectionStore.ref.current);
                        break;
                    case NavigationMenu.Request:
                        scrollTransitionStore.animateTo(requestSectionStore.ref.current);
                        break;
                }
            }
        };
    }
    __decorate([
        observable
    ], Store.prototype, "menu", void 0);
    __decorate([
        observable
    ], Store.prototype, "indicator", void 0);
    __decorate([
        action
    ], Store.prototype, "change", void 0);
    return Store;
}());
export default new Store;
export { Store };
