var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observable, computed } from 'mobx';
import popupStore from '.';
var Store = /** @class */ (function () {
    function Store() {
        this.ref = React.createRef();
    }
    Object.defineProperty(Store.prototype, "entity", {
        get: function () {
            return popupStore.entity || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "title", {
        get: function () {
            var entity = this.entity;
            return entity.title || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "subtitle", {
        get: function () {
            var entity = this.entity;
            var date = (function () {
                var time = entity.time || 0;
                var date = new Date(time * 1000);
                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                return year + '년 ' + month + '월 ' + day + '일';
            })();
            var name = (entity.name || '');
            var encryptedName = name.charAt(0) + name.substr(1).replace(/./g, '○');
            return (encryptedName || '') + ',  ' + (date || '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "contents", {
        get: function () {
            var entity = this.entity;
            return entity.contents || '';
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable
    ], Store.prototype, "ref", void 0);
    __decorate([
        computed
    ], Store.prototype, "entity", null);
    __decorate([
        computed
    ], Store.prototype, "title", null);
    __decorate([
        computed
    ], Store.prototype, "subtitle", null);
    __decorate([
        computed
    ], Store.prototype, "contents", null);
    return Store;
}());
export default new Store;
export { Store };
