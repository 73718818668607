import getWindowScroll from './getWindowScroll';
export default function (element) {
    var windowScroll = getWindowScroll();
    var rect = element.getBoundingClientRect();
    return {
        top: rect.top + windowScroll.top,
        right: rect.right + windowScroll.left,
        bottom: rect.bottom + windowScroll.top,
        left: rect.left + windowScroll.left,
        width: rect.width,
        height: rect.height,
    };
}
