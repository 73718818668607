exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__35ZZb {\n  float: left;\n  box-sizing: border-box;\n  margin-bottom: 32px;\n  width: calc( ( 100% - ( 16px * 1 ) ) / 2); }\n  .container__35ZZb:not(:nth-child(2n+2)) {\n    margin-right: 16px; }\n  .container__35ZZb:nth-child(2n+3) {\n    clear: both; }\n  .container__35ZZb:nth-last-child(1) {\n    margin-bottom: 0; }\n  .container__35ZZb:nth-last-child(2) {\n    margin-bottom: 0; }\n  .container__35ZZb:after {\n    display: block;\n    clear: both;\n    content: ''; }\n\n.title__3UtPa {\n  line-height: 1.4em;\n  font-size: 14px;\n  font-weight: bold;\n  margin-bottom: 16px;\n  color: black;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  line-height: 1.4em;\n  max-height: calc(1.4em * 1); }\n\n.subtitle__2-ozu {\n  font-size: 14px;\n  line-height: 1px;\n  color: black;\n  margin-bottom: 16px; }\n\n.contents__Tknfb {\n  font-size: 14px;\n  color: black;\n  margin-bottom: 16px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3;\n  line-height: 1.75em;\n  height: calc(1.75em * 3); }\n\n.moreButton__2JfUY {\n  width: 100%;\n  text-align: center;\n  color: white;\n  font-size: 14px;\n  line-height: 1em;\n  background: black;\n  padding: 16px 0;\n  cursor: pointer;\n  -webkit-transition: text-shadow 0.1s ease-out, box-shadow 0.1s ease-out;\n  transition: text-shadow 0.1s ease-out, box-shadow 0.1s ease-out;\n  text-shadow: 0 0 0px white;\n  box-shadow: 0 0 0px rgba(0, 0, 0, 0.25); }\n  .moreButton__2JfUY:hover {\n    text-shadow: 0 0 16px white;\n    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25); }\n", ""]);

// Exports
exports.locals = {
	"container": "container__35ZZb",
	"title": "title__3UtPa",
	"subtitle": "subtitle__2-ozu",
	"contents": "contents__Tknfb",
	"moreButton": "moreButton__2JfUY"
};