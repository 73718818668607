var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observable, toJS } from 'mobx';
var Store = /** @class */ (function () {
    function Store() {
        this.ref = React.createRef();
    }
    Store.prototype.toJS = function () {
        return {
            name: toJS(this.name),
            phone: toJS(this.phone1) + '-' + toJS(this.phone2) + '-' + toJS(this.phone3),
            contents: toJS(this.contents),
            agree: toJS(this.agree),
        };
    };
    __decorate([
        observable
    ], Store.prototype, "ref", void 0);
    __decorate([
        observable
    ], Store.prototype, "name", void 0);
    __decorate([
        observable
    ], Store.prototype, "phone1", void 0);
    __decorate([
        observable
    ], Store.prototype, "phone2", void 0);
    __decorate([
        observable
    ], Store.prototype, "phone3", void 0);
    __decorate([
        observable
    ], Store.prototype, "contents", void 0);
    __decorate([
        observable
    ], Store.prototype, "agree", void 0);
    return Store;
}());
export default new Store;
export { Store };
