var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, computed, observe, toJS } from 'mobx';
import { getWindowHeight, getDocumentHeight, getElementAbsoluteRect } from '@/utils';
import ScrollStore from '@/stores/ui/scroll';
var Store = /** @class */ (function () {
    function Store() {
        var _this = this;
        // 스크롤 Interval
        this.interval = undefined;
        // 스크롤 이동
        this.animateTo = function (target, _transition) {
            if (_transition === void 0) { _transition = true; }
            _this.interval = undefined;
            var _top = toJS(ScrollStore.value);
            var targetTop = getElementAbsoluteRect(target).top;
            var impossibleTopDistanceToReach = Math.max(0, getWindowHeight() - (getDocumentHeight() - targetTop)); // 화면 최하단으로는 못 가니까
            var interval = setInterval(function () {
                var top = toJS(ScrollStore.value);
                var topDistance = targetTop - top;
                var topOffset = topDistance * 0.0333;
                _top += topOffset;
                window.scrollTo(window.scrollX, _top);
                // 스크롤 이동 종료
                if ((Math.abs(topDistance) <= 1) // 1. 남은 거리가 1px 보다 작으면
                    || ((topDistance | 0) === (impossibleTopDistanceToReach | 0)) // 2. 도달할 수 없는 거리(위치)에 닿았을 때
                ) {
                    window.scrollTo(window.scrollX, targetTop);
                    _this.interval = undefined;
                    clearInterval(_this.interval);
                }
                console.log(topDistance | 0, impossibleTopDistanceToReach | 0, ((topDistance | 0) === (impossibleTopDistanceToReach | 0)));
            });
            _this.interval = interval;
        };
        // 등록된 interval이 undefined가 되면 자동으로 clearInterval
        observe(this, 'interval', function (_a) {
            var oldValue = _a.oldValue, newValue = _a.newValue;
            var oldInterval = oldValue;
            var newInterval = newValue;
            if (!newInterval)
                clearInterval(oldInterval);
        });
    }
    Object.defineProperty(Store.prototype, "animating", {
        // 현재 스크롤중인지?
        get: function () {
            return (typeof this.interval !== 'undefined');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable
    ], Store.prototype, "interval", void 0);
    __decorate([
        computed
    ], Store.prototype, "animating", null);
    __decorate([
        action
    ], Store.prototype, "animateTo", void 0);
    return Store;
}());
export default new Store;
export { Store };
// todo : 좀 직관적으로 보이게 리팩토링해야할듯
