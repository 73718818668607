exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__27Grf {\n  display: block;\n  max-width: 1440px;\n  margin: 32px auto; }\n\n.wrapper__1TYqP {\n  max-width: calc(100% - 16px * 2);\n  margin-left: 16px; }\n\n.title__1Ky-L {\n  display: block;\n  font-weight: bold;\n  font-size: 18px;\n  margin-bottom: 32px; }\n\n.loadMoreButton__3ZJHw {\n  width: 100%;\n  margin: 32px auto 16px auto;\n  text-align: center;\n  line-height: 1em;\n  font-size: 14px;\n  padding: 16px 0;\n  border: 1px solid;\n  box-sizing: border-box;\n  -webkit-transition: text-shadow 0.1s ease-out, box-shadow 0.1s ease-out;\n  transition: text-shadow 0.1s ease-out, box-shadow 0.1s ease-out;\n  text-shadow: 0 0px white;\n  box-shadow: 0 0 0px rgba(0, 0, 0, 0); }\n  .loadMoreButton__3ZJHw > * {\n    vertical-align: middle; }\n  .loadMoreButton__3ZJHw:hover {\n    text-shadow: 0 0 16px white;\n    box-shadow: 0 5.33333px 16px rgba(0, 0, 0, 0.1); }\n  .loadMoreButton__3ZJHw[data-active=true] {\n    cursor: pointer; }\n  .loadMoreButton__3ZJHw[data-active=false] {\n    pointer-events: none;\n    opacity: 0.25; }\n", ""]);

// Exports
exports.locals = {
	"container": "container__27Grf",
	"wrapper": "wrapper__1TYqP",
	"title": "title__1Ky-L",
	"loadMoreButton": "loadMoreButton__3ZJHw"
};