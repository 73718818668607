exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__79w-G {\n  display: block;\n  width: 100%;\n  background: #f9f9f9;\n  font-size: 16px; }\n\n.wrapper__1PXXO {\n  max-width: calc(1440px);\n  padding: 36px;\n  box-sizing: border-box;\n  margin: 0 auto; }\n\n.leftSection__3c75i {\n  width: 50%;\n  float: left; }\n  .leftSection__3c75i > table {\n    width: 100%;\n    table-layout: fixed; }\n    .leftSection__3c75i > table td {\n      font-size: 14px;\n      line-height: 1em;\n      padding-bottom: 12px; }\n    .leftSection__3c75i > table tr:last-child td {\n      padding-bottom: 0; }\n\n.rightSection__1JM1u {\n  width: 50%;\n  float: left; }\n  .rightSection__1JM1u > table {\n    width: 100%;\n    table-layout: fixed; }\n    .rightSection__1JM1u > table td {\n      font-size: 14px;\n      line-height: 1em;\n      padding-bottom: 12px; }\n      .rightSection__1JM1u > table td:nth-child(1) {\n        width: 20%; }\n      .rightSection__1JM1u > table td:nth-child(2) {\n        width: 80%; }\n    .rightSection__1JM1u > table tr:last-child td {\n      padding-bottom: 0; }\n", ""]);

// Exports
exports.locals = {
	"container": "container__79w-G",
	"wrapper": "wrapper__1PXXO",
	"leftSection": "leftSection__3c75i",
	"rightSection": "rightSection__1JM1u"
};