import * as React from 'react';
import { Desktop, Mobile } from '@/responsive';
import DesktopComponent from './desktop';
import MobileComponent from './mobile';
export default function () {
    return (React.createElement("div", null,
        React.createElement(Desktop, null,
            React.createElement(DesktopComponent, null)),
        React.createElement(Mobile, null,
            React.createElement(MobileComponent, null))));
}
