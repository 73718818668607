var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import Icon from '@/components/Icon';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setImageIndex = function (index) {
            _this.props.setImageIndex(index);
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "entity", {
        get: function () {
            return this.props.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "images", {
        get: function () {
            return this.props.images;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "selectedIndex", {
        get: function () {
            return this.props.selectedIndex;
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.scroller }, this.images.map(function (image, index) {
                var style = {
                    backgroundImage: "url(" + image.mediumSizeUrl + ")"
                };
                return (React.createElement("div", { key: index, className: styles.item, onClick: function () { return _this.setImageIndex(index); }, "data-selected": index == _this.selectedIndex, style: style },
                    React.createElement("div", { className: styles.icon, "data-show": index == _this.selectedIndex },
                        React.createElement(Icon, { name: "eye", color: "black" }))));
            }))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            entity: stores.ui.popup.entity,
            images: stores.ui.popup.galleryStore.images,
            selectedIndex: stores.ui.popup.galleryStore.selectedIndex,
            setImageIndex: stores.ui.popup.galleryStore.set
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
// todo : type이 명시 안되어있으니 이상한 조건식들이 추가되는구만...
