import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
function start() {
    ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
}
export { start };
