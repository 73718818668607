var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import Icon from '@/components/Icon';
import { Type as PopupType } from '@/stores/ui/popup';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function () {
            _this.popupStore.close();
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "popupStore", {
        get: function () {
            return this.props.popupStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "visible", {
        get: function () {
            return this.popupStore.opened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "dark", {
        get: function () {
            return this.popupStore.type === PopupType.Gallery;
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        return (React.createElement("div", { className: styles.button, onClick: this.onClick, "data-show": this.visible },
            React.createElement(Icon, { name: "close", color: this.dark ? 'white' : 'black' })));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            popupStore: stores.ui.popup,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
