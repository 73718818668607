import scroll from './scroll';
import navigation from './navigation';
import popup from './popup';
import homeSection from './homeSection';
import workSection from './workSection';
import reviewSection from './reviewSection';
import officeSection from './officeSection';
import requestSection from './requestSection';
import footer from './footer';
export default {
    scroll: scroll,
    navigation: navigation,
    popup: popup,
    homeSection: homeSection,
    workSection: workSection,
    reviewSection: reviewSection,
    officeSection: officeSection,
    requestSection: requestSection,
    footer: footer
};
