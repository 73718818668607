var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './mobile.scss';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Component.prototype, "configStore", {
        get: function () {
            return this.props.configStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "officeStore", {
        get: function () {
            return this.props.officeStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "fotterStore", {
        get: function () {
            return this.props.footerStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "email", {
        get: function () {
            var config = this.configStore;
            return config.company.email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "foundingYear", {
        get: function () {
            var config = this.configStore;
            return config.company.foundingYear;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "currentYear", {
        get: function () {
            var config = this.configStore;
            return config.currentYear;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "offices", {
        get: function () {
            var officeStore = this.officeStore;
            return officeStore.entities || [];
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.informationSection },
                React.createElement("table", null,
                    React.createElement("tbody", null,
                        this.offices.map(function (office, index) { return (React.createElement(React.Fragment, { key: index },
                            React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement("span", { style: { fontWeight: 'bold' } }, office.name))),
                            React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement("span", null, "\uC8FC\uC18C")),
                                React.createElement("td", { style: { letterSpacing: '-0.01em' } }, office.address)),
                            React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement("span", null, "\uC804\uD654\uBC88\uD638")),
                                React.createElement("td", null, office.phone)),
                            React.createElement("tr", null,
                                React.createElement("td", null)),
                            React.createElement("tr", null,
                                React.createElement("td", null)))); }),
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "\uC774\uBA54\uC77C")),
                            React.createElement("td", null, this.email))))),
            React.createElement("div", { className: styles.margin }),
            React.createElement("div", { className: styles.copyrightSection },
                React.createElement("table", null,
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "J&J \uC778\uD14C\uB9AC\uC5B4"))),
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                "since ",
                                this.foundingYear)),
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                "Copyright \u00A9 ",
                                this.foundingYear,
                                " - ",
                                this.currentYear,
                                " J&J interior")),
                        React.createElement("tr", null,
                            React.createElement("td", null, "All Rights Reserved")),
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                "Powered by\u00A0",
                                React.createElement("a", { href: "https://bingostud.io", target: "_blank", style: { fontWeight: 'bold' } }, "Bingo Studio"))))))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            configStore: stores.domain.config,
            officeStore: stores.domain.office,
            footerStore: stores.ui.footer,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
