var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from 'mobx';
import articleStore from './article';
import galleryStore from './gallery';
var Type;
(function (Type) {
    Type["Article"] = "article";
    Type["Gallery"] = "gallery";
})(Type || (Type = {}));
var Store = /** @class */ (function () {
    function Store() {
        var _this = this;
        this._opened = false;
        this.open = function (type, entity) {
            _this._opened = true;
            _this._type = type;
            _this._entity = entity;
        };
        this.close = function () {
            _this._opened = false;
        };
    }
    Object.defineProperty(Store.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "type", {
        get: function () {
            return this._type || Type.Article;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "entity", {
        get: function () {
            return this._entity || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "articleStore", {
        get: function () {
            return articleStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "galleryStore", {
        get: function () {
            return galleryStore;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable
    ], Store.prototype, "_opened", void 0);
    __decorate([
        observable
    ], Store.prototype, "_type", void 0);
    __decorate([
        observable
    ], Store.prototype, "_entity", void 0);
    __decorate([
        action
    ], Store.prototype, "open", void 0);
    __decorate([
        action
    ], Store.prototype, "close", void 0);
    return Store;
}());
export default new Store;
export { Store, Type };
