import config from './config';
import office from './office';
import request from './request';
import review from './review';
import work from './work';
export default {
    config: config,
    office: office,
    request: request,
    review: review,
    work: work,
};
