exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__3gEE3 {\n  display: block;\n  width: 100%;\n  padding: 18px 18px 36px 18px;\n  box-sizing: border-box;\n  background: #f9f9f9; }\n\n.informationSection__3lnzN {\n  width: 100%; }\n  .informationSection__3lnzN > table {\n    width: 100%;\n    table-layout: fixed; }\n    .informationSection__3lnzN > table td {\n      font-size: 14px;\n      line-height: 1em;\n      padding-bottom: 12px; }\n      .informationSection__3lnzN > table td:nth-child(1) {\n        width: 25%; }\n      .informationSection__3lnzN > table td:nth-child(2) {\n        width: 80%; }\n    .informationSection__3lnzN > table tr:last-child td {\n      padding-bottom: 0; }\n\n.copyrightSection__1qw85 {\n  width: 100%; }\n  .copyrightSection__1qw85 > table {\n    width: 100%;\n    table-layout: fixed; }\n    .copyrightSection__1qw85 > table td {\n      font-size: 14px;\n      line-height: 1em;\n      padding-bottom: 12px; }\n    .copyrightSection__1qw85 > table tr:last-child td {\n      padding-bottom: 0; }\n\n.margin__3E5GJ {\n  height: 36px; }\n", ""]);

// Exports
exports.locals = {
	"container": "container__3gEE3",
	"informationSection": "informationSection__3lnzN",
	"copyrightSection": "copyrightSection__1qw85",
	"margin": "margin__3E5GJ"
};