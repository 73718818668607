var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import styles from './style.scss';
import Icon from '@/components/Icon';
import Carousel from 'nuka-carousel';
import { inject, observer } from 'mobx-react';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Component.prototype.componentDidMount = function () {
        var _this = this;
        setInterval(function () {
            _this.refs.carousel.setDimensions();
            // fir for IE 10 below : height 0
        }, 500);
    };
    Component.prototype.render = function () {
        var sections = this.props.sections;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.contents },
                React.createElement("div", null,
                    React.createElement(Carousel, { ref: "carousel", renderCenterRightControls: function (_a) {
                            var nextSlide = _a.nextSlide, currentSlide = _a.currentSlide, slideCount = _a.slideCount;
                            return (React.createElement("div", { className: styles.previousButton, "data-show": currentSlide < slideCount - 1, onClick: nextSlide },
                                React.createElement(Icon, { name: "arrow" })));
                        }, renderCenterLeftControls: function (_a) {
                            var previousSlide = _a.previousSlide, currentSlide = _a.currentSlide;
                            return (React.createElement("div", { className: styles.nextButton, "data-show": currentSlide > 0, onClick: previousSlide },
                                React.createElement(Icon, { name: "arrow" })));
                        }, renderBottomCenterControls: function (_) { return (null); }, easing: 'easeQuadInOut', edgeEasing: 'easeQuadInOut', speed: 500 }, sections.map(function (section, index) { return (React.createElement("div", { key: index },
                        React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + section.image.sizes.large + ")" } }),
                        React.createElement("div", { className: styles.title }, section.description))); }))))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            sections: stores.domain.config.homeSection.sections || [],
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
