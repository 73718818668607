var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import { Type as PopupType } from '@/stores/ui/popup';
import Icon from '@/components/Icon';
import SubImages from './subImages';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePreviousButtonClick = function () {
            var previous = _this.props.previous;
            previous();
        };
        _this.handleNextButtonClick = function () {
            var next = _this.props.next;
            next();
        };
        return _this;
    }
    Component.prototype.render = function () {
        var _a = this.props, title = _a.title, showing = _a.showing, image = _a.image, showNextButton = _a.showNextButton, showPreviousButton = _a.showPreviousButton;
        var _b = this, handlePreviousButtonClick = _b.handlePreviousButtonClick, handleNextButtonClick = _b.handleNextButtonClick;
        return (React.createElement("div", { className: styles.container, "data-show": showing },
            React.createElement("div", { className: styles.title }, title),
            React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + image.largeSizeUrl + ")" } }),
            React.createElement("div", { className: styles.previousButton, "data-show": showPreviousButton, onClick: function () { return handlePreviousButtonClick(); } },
                React.createElement(Icon, { name: "arrow", color: "white" })),
            React.createElement("div", { className: styles.nextButton, "data-show": showNextButton, onClick: function () { return handleNextButtonClick(); } },
                React.createElement(Icon, { name: "arrow", color: "white" })),
            React.createElement(SubImages, null)));
    };
    Component = __decorate([
        inject(function (stores) {
            var popup = stores.ui.popup;
            var gallery = popup.galleryStore;
            return {
                title: popup.entity.title,
                previous: gallery.previous,
                next: gallery.next,
                image: gallery.selectedImage,
                showing: popup.opened && (popup.type === PopupType.Gallery),
                showNextButton: !gallery.last,
                showPreviousButton: !gallery.first,
            };
        }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
