var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import { PlaceType } from '../..';
import { Type as PopupType } from '@/stores/ui/popup';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (index) {
            if (index === void 0) { index = 0; }
            var openPopup = _this.props.openPopup;
            openPopup(_this.props.entity, index);
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "entity", {
        get: function () {
            var entity = this.props.entity;
            return entity || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "title", {
        get: function () {
            return this.entity.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "subtitle", {
        get: function () {
            try {
                return PlaceType[this.entity.placeType];
            }
            catch (e) {
                return '알 수 없음';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "mainImageURL", {
        get: function () {
            try {
                return this.entity.images[0].image.sizes.medium;
            }
            catch (e) {
                console.error('image does not exist');
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "subImages", {
        get: function () {
            var imageURLs = [];
            try {
                if (this.entity.images.length >= 2)
                    imageURLs = imageURLs.concat(this.entity.images[1].image.sizes.medium);
            }
            catch (error) {
                console.error('image does not exist');
            }
            try {
                if (this.entity.images.length >= 3)
                    imageURLs = imageURLs.concat(this.entity.images[2].image.sizes.medium);
            }
            catch (error) {
                console.error('image does not exist');
            }
            return imageURLs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "moreImageURL", {
        get: function () {
            if (this.moreImageCount <= 0)
                return '';
            try {
                return this.entity.images[3].image.sizes.thumbnail;
            }
            catch (error) {
                console.error('image does not exist');
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "moreImageCount", {
        get: function () {
            return Math.max(0, this.entity.images.length - 3);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "placeSize", {
        get: function () {
            var placeSize = this.entity.placeSize;
            return placeSize ? placeSize + '평' : '';
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.mainImage, style: { backgroundImage: 'url(' + this.mainImageURL + ')' }, onClick: function () { return _this.handleClick(0); } }),
            React.createElement("div", { className: styles.subImageContainer },
                this.subImages.map(function (url, index) { return (React.createElement("div", { key: index, className: styles.subImage, style: { backgroundImage: 'url(' + url + ')' }, onClick: function () { return _this.handleClick(index + 1); } })); }),
                this.moreImageURL &&
                    React.createElement("div", { className: styles.subImage, style: { backgroundImage: 'url(' + this.moreImageURL + ')' }, onClick: function () { return _this.handleClick(_this.subImages.length); } },
                        React.createElement("div", null,
                            React.createElement("div", null,
                                "+",
                                this.moreImageCount)))),
            React.createElement("div", { className: styles.informationContainer, onClick: function () { return _this.handleClick(0); } },
                React.createElement("div", { className: styles.title }, this.title),
                React.createElement("div", { className: styles.subtitle }, this.subtitle),
                React.createElement("div", { className: styles.sizeText }, this.placeSize)),
            React.createElement("div", { style: { display: 'none' } },
                React.createElement("div", { className: styles.moreButton }, "\uC0AC\uC9C4 \uBCF4\uAE30"))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            openPopup: function (entity, imageIndex) {
                var store = stores.ui.popup;
                store.galleryStore.selectedIndex = imageIndex;
                store.open(PopupType.Gallery, entity);
            }
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
