exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__Zp2HK {\n  position: absolute;\n  display: block;\n  width: 100%;\n  margin: 0 auto;\n  background: white;\n  padding: 18px 0 36px 0;\n  border-radius: 9px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 16px 36px rgba(0, 0, 0, 0.05);\n  margin-bottom: 36px;\n  -webkit-transition: 0.2s opacity;\n  transition: 0.2s opacity; }\n  .container__Zp2HK[data-show=true] {\n    opacity: 1; }\n  .container__Zp2HK[data-show=false] {\n    opacity: 0;\n    pointer-events: none; }\n\n.wrapper__1jgCG {\n  max-width: calc(100% - 18px * 2);\n  margin-left: 18px; }\n\n.title__Dk5xr {\n  display: block;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 1em;\n  margin-bottom: 13.5px;\n  color: black; }\n\n.subtitle__3hBzZ {\n  font-size: 14px;\n  margin-bottom: 36px;\n  line-height: 1em;\n  color: rgba(0, 0, 0, 0.5); }\n\n.contents__yMvKj {\n  font-size: 14px;\n  line-height: 2.0em;\n  color: black; }\n", ""]);

// Exports
exports.locals = {
	"container": "container__Zp2HK",
	"wrapper": "wrapper__1jgCG",
	"title": "title__Dk5xr",
	"subtitle": "subtitle__3hBzZ",
	"contents": "contents__yMvKj"
};