var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './style.scss';
import { NavigationMenu, ScrollDirection } from '@/enums';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._cachedShowLogo = true;
        _this.handleMenuClick = function (menu) {
            var change = _this.navigationStore.change;
            change(menu, true);
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "configStore", {
        get: function () {
            return this.props.configStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "navigationStore", {
        get: function () {
            return this.props.navigationStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "scrollStore", {
        get: function () {
            return this.props.scrollStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "showLogo", {
        get: function () {
            var scroll = this.scrollStore;
            var scrollValue = scroll.value;
            var scrollDirection = scroll.direction;
            var scrollLastDirection = scroll.lastDirection;
            var scrollIsSignificant = scroll.isSignificant;
            if (!scrollIsSignificant)
                return this._cachedShowLogo; // 의미 없는 수준의 스크롤이면 무시하고 캐시값 반환
            return this._cachedShowLogo = (scrollValue <= 0 // 최상단 스크롤
                || scrollDirection === ScrollDirection.Up // 현재 스크롤 방향이 윗쪽
                || scrollLastDirection === ScrollDirection.Up // 이전 스크롤 방향이 윗쪽
            );
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "showBackground", {
        get: function () {
            return !this.showLogo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "showBackgroundBorderLine", {
        get: function () {
            var scroll = this.scrollStore;
            var scrollTop = scroll.value;
            return (!this.showLogo || scrollTop > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "floatMenuBar", {
        get: function () {
            return !this.showLogo;
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.render = function () {
        var _this = this;
        var menu = this.navigationStore.menu;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.background, "data-show": this.showBackground, "data-show-borderline": this.showBackgroundBorderLine }),
            React.createElement("ul", { className: styles.menuBar, "data-float": this.floatMenuBar },
                React.createElement("li", { className: styles.menuItem, "data-selected": menu === NavigationMenu.Home, onClick: function () { _this.handleMenuClick(NavigationMenu.Home); } }, "\uC18C\uAC1C"),
                React.createElement("li", { className: styles.menuItem, "data-selected": menu === NavigationMenu.Work, onClick: function () { _this.handleMenuClick(NavigationMenu.Work); } }, "\uACF5\uC0AC/\uC791\uC5C5"),
                React.createElement("li", { className: styles.menuItem, "data-selected": menu === NavigationMenu.Review, onClick: function () { _this.handleMenuClick(NavigationMenu.Review); } }, "\uACE0\uAC1D \uD6C4\uAE30"),
                React.createElement("li", { className: styles.menuItem, "data-selected": menu === NavigationMenu.Office, onClick: function () { _this.handleMenuClick(NavigationMenu.Office); } }, "\uC0AC\uBB34\uC2E4"),
                React.createElement("li", { className: styles.menuItem, "data-selected": menu === NavigationMenu.Request, onClick: function () { _this.handleMenuClick(NavigationMenu.Request); } }, "\uC0C1\uB2F4/\uC804\uD654"))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            configStore: stores.domain.config,
            navigationStore: stores.ui.navigation,
            scrollStore: stores.ui.scroll,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
//  todo : showLogo는 빼야함...  store쪽에서 계산해서 공용으로 쓰게... applicationStore 라도 만들어야하나 거기 안에 navigation도 넣고 다 넣는거지
