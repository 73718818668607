var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { observable, computed, action } from 'mobx';
import popupStore from '.';
var Store = /** @class */ (function () {
    function Store() {
        var _this = this;
        this.ref = React.createRef();
        this.selectedIndex = 0;
        this.set = function (index) {
            _this.selectedIndex = Math.max(Math.min(index, _this.images.length - 1), 0);
        };
        this.next = function () {
            _this.selectedIndex = Math.min(_this.selectedIndex + 1, _this.images.length - 1);
        };
        this.previous = function () {
            _this.selectedIndex = Math.max(_this.selectedIndex - 1, 0);
        };
    }
    Object.defineProperty(Store.prototype, "selectedImage", {
        get: function () {
            var images = this.images;
            if (!images.length)
                return {};
            return this.images[this.selectedIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "last", {
        get: function () {
            return this.images.length - 1 <= this.selectedIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "first", {
        get: function () {
            return this.selectedIndex <= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "images", {
        get: function () {
            var entity = popupStore.entity;
            if (!entity)
                return [];
            if (!entity.images)
                return [];
            return entity.images.map(function (image) { return ({
                title: image.title,
                description: image.description,
                originalSizeUrl: image.image.url,
                largeSizeUrl: image.image.sizes.large,
                mediumSizeUrl: image.image.sizes.medium,
                thumbnailSizeUrl: image.image.sizes.thumbnail,
            }); });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable
    ], Store.prototype, "ref", void 0);
    __decorate([
        observable
    ], Store.prototype, "selectedIndex", void 0);
    __decorate([
        computed
    ], Store.prototype, "selectedImage", null);
    __decorate([
        computed
    ], Store.prototype, "last", null);
    __decorate([
        computed
    ], Store.prototype, "first", null);
    __decorate([
        computed
    ], Store.prototype, "images", null);
    __decorate([
        action
    ], Store.prototype, "set", void 0);
    __decorate([
        action
    ], Store.prototype, "next", void 0);
    __decorate([
        action
    ], Store.prototype, "previous", void 0);
    return Store;
}());
export default new Store;
export { Store };
