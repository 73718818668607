var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import { getWindowHeight, getDocumentHeight, getWindowScroll } from '@/utils';
import { ScrollDirection } from '@/enums';
/*
import transitionStore from './transition'
import { Store as ScrollTransitionStore } from './transition'
*/
var Store = /** @class */ (function () {
    // 생성자
    function Store() {
        var _this = this;
        /*
        // transition store
        get transitionStore():ScrollTransitionStore{
          return transitionStore
        }
        */
        // 스크롤(Y값)
        this._value = 0;
        // 직전 스크롤(Y값)
        this.oldValue = 0;
        // 스크롤(Y값) 속도
        this._force = 0;
        // 스크롤(Y값) 방향
        this._direction = ScrollDirection.Static;
        // 직전 스크롤(Y값) 방향
        this.oldDirection = ScrollDirection.Static;
        // 이전 스크롤(Y값) 방향
        this.lastDirection = ScrollDirection.Static;
        // 1. window에서 scroll 이벤트 감지
        // 2. scrollTop 값을 받아와서 set
        window.addEventListener('scroll', function () {
            _this.value = getWindowScroll().top;
        });
        // 스크롤 관성값에 계속해서 하중치를 곱해서 값을 줄임. // todo : 이게 비동기적으로 하는 방식이라 무결하지 않아보임.
        setInterval(function () {
            _this.force = _this.force * 0.1;
        }, 100);
    }
    Object.defineProperty(Store.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            this.oldValue = this._value;
            this._value = newValue;
            this.force += this.offset / getWindowHeight(); // 스크롤 관성를 계속 더한다. 여기서 더하고, 실시간으로 하중치를 곱해서 값을 줄임.
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "percent", {
        // 스크롤(Y값) 퍼센트
        get: function () {
            return this.value / (getDocumentHeight() - getWindowHeight());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "offset", {
        // 스크롤(Y값) 변화
        get: function () {
            return this._value - this.oldValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "force", {
        get: function () {
            return this._force;
        },
        set: function (value) {
            this._force = value;
            // 스크롤(Y값) 방향을 지정
            if (this._force > this.significantForce)
                this.direction = ScrollDirection.Down;
            else if (this._force < (-1 * this.significantForce))
                this.direction = ScrollDirection.Up;
            else
                this.direction = ScrollDirection.Static;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "significantForce", {
        // 의미있는 관성 수치 (~이상) : 이 수치 이하로는 의미없는 관성이라고 판단한다.
        get: function () {
            return 0.0015;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "isSignificant", {
        // 의미있는 스크롤인지 판단(boolean)함.
        get: function () {
            return Math.abs(this.force) >= this.significantForce;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "direction", {
        get: function () {
            return this._direction;
        },
        set: function (newDirection) {
            var oldDirection = this.oldDirection;
            // '직전 스크롤(Y값) 방향'과 '(새로운) 스크롤(Y값) 방향'이 다를 경우, '이전 스크롤(Y값) 방향'으로 설정
            if (newDirection != oldDirection)
                this.lastDirection = oldDirection;
            this.oldDirection = this._direction;
            this._direction = newDirection;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable
    ], Store.prototype, "_value", void 0);
    __decorate([
        computed
    ], Store.prototype, "percent", null);
    __decorate([
        observable
    ], Store.prototype, "oldValue", void 0);
    __decorate([
        computed
    ], Store.prototype, "offset", null);
    __decorate([
        observable
    ], Store.prototype, "_force", void 0);
    __decorate([
        computed
    ], Store.prototype, "isSignificant", null);
    __decorate([
        observable
    ], Store.prototype, "_direction", void 0);
    __decorate([
        observable
    ], Store.prototype, "oldDirection", void 0);
    __decorate([
        observable
    ], Store.prototype, "lastDirection", void 0);
    return Store;
}());
// Store를 생성해서 내보냄
export default new Store;
export { Store };
// todo : 직전과 이전 이라는 말이 헷갈림
// todo : set get 이 public으로 그냥 노출되어있음. 이게 특히 set에서 부가적인 로직이 들어가서 어쩔수없는데... https://mobx.js.org/intro/overview.html 읽어서 reaction, when, 같은 reactive한 방식으로 바꿔야함!
// todo : https://mobx.js.org/intro/overview.html 를 다 읽어서 활용하던지.... oldValue, newValue 는 helper같은걸 하나 만들어서 해결해야함 : https://github.com/mobxjs/mobx/issues/1785 -> observe 쓰면 됨 -> observer가 object도 되나?
// todo : store안에 어디까지 포함 시켜야할지 고민해보자
// todo : transition store을 여기서 제공해줘야하지 않을까? 너무 나중에 import 다른곳에서 하면 나중에 혼자 initialized 되서 뭔가 문제 생길거 같은데
