var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { Desktop, Mobile } from '@/responsive';
import DesktopComponent from './desktop';
import MobileComponent from './mobile';
import { observer, inject } from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';
import { NavigationMenu } from '@/enums';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChange = function (isVisible) {
            if (!(_this.state || {}).mounted)
                return;
            if (isVisible) {
                _this.navigationStore.change(NavigationMenu.Office);
            }
        };
        return _this;
    }
    Object.defineProperty(Component.prototype, "navigationStore", {
        get: function () {
            return this.props.navigationStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "officeSectionStore", {
        get: function () {
            return this.props.officeSectionStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "ref", {
        get: function () {
            return this.officeSectionStore.ref;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Component.prototype, "visibilitySensor", {
        get: function () {
            return this.navigationStore.indicator.current || undefined;
        },
        enumerable: true,
        configurable: true
    });
    Component.prototype.componentDidMount = function () {
        this.setState({ mounted: true });
    };
    Component.prototype.render = function () {
        var mounted = (this.state || {}).mounted;
        return (React.createElement("div", { ref: this.ref },
            React.createElement(VisibilitySensor, { active: mounted, onChange: this.onChange, resizeCheck: true, scrollCheck: true, scrollDelay: 0, partialVisibility: true, containment: this.visibilitySensor },
                React.createElement("div", null,
                    React.createElement(Desktop, null,
                        React.createElement(DesktopComponent, null)),
                    React.createElement(Mobile, null,
                        React.createElement(MobileComponent, null))))));
    };
    Component = __decorate([
        inject(function (stores) { return ({
            navigationStore: stores.ui.navigation,
            officeSectionStore: stores.ui.officeSection,
        }); }),
        observer
    ], Component);
    return Component;
}(React.Component));
export default Component;
